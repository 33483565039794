import React from "react";
import { ThemeProvider } from "styled-components";

import { Toaster } from "@hyperlocal/vital2";
import { Provider, applyTheme } from "@hyperlocal/vital-theme";
import {
  Auth,
  DebuggingTrack,
  AuthLocationStore,
  CurrentUserStore,
  setupAuthUtility,
} from "@hyperlocal/auth-utility";

import { OnboardingManager } from "@Libs/onboardingManager";

import useNavigate from "@Store/navigate";
import useGlobalLoading from "@Store/globalLoading";

import useNavigationHistoryControl from "@Hooks/useNavigationHistoryControl";

import { VISIBLE_PAGE } from "@Constants/views";
import { VITAL_THEMES } from "@Constants/appConstants";

import GlobalPageLoader from "@Components/GlobalPageLoader";
import DynamicPageLoader from "@Components/DynamicPageLoader";

import "./globals.css";

const App: IComponent<MFEProps> = (props) => {
  const MFEProps: MFEProps = props;

  const navigate = useNavigate((state) => state.navigate);
  const { isGlobalLoading, clearLoadingState } = useGlobalLoading((state) => ({
    isGlobalLoading: state.isGlobalLoading,
    clearLoadingState: state.clearLoadingState,
  }));

  const setCurrentUser = CurrentUserStore((state) => state.setCurrentUser);
  const setSelectedAuthLocation = AuthLocationStore(
    (state) => state.setSelectedAuthLocation
  );

  const [isAuthUtilityReady, setIsAuthUtilityReady] =
    React.useState<boolean>(false);

  const [isCheckingWhereToStart, setIsCheckingWhereToStart] =
    React.useState<boolean>(true);

  const vitalTheme = VITAL_THEMES[MFEProps.theme];

  const navigationHistoryControl = useNavigationHistoryControl();

  const handleAlreadySignedInUser = async () => {
    const isAuthenticated = await Auth.isSignedInUser();

    if (isAuthenticated) return Auth.handleSignout();
  };

  const fillStoreWithPropsData = async () => {
    const { userFromCustomLink } = MFEProps;

    const navigateToLoginCredentials = () => {
      navigate(VISIBLE_PAGE.LOGIN_CREDENTIALS, {
        isCommingFromCustomLink: true,
      });
    };

    if (userFromCustomLink) {
      setCurrentUser({ email: userFromCustomLink?.email || "" });
      setSelectedAuthLocation(userFromCustomLink?.authLocation || {});

      navigateToLoginCredentials();
    }

    const customNavigate = userFromCustomLink
      ? navigateToLoginCredentials
      : undefined;

    navigationHistoryControl.setEventHanlder(customNavigate);
  };

  React.useEffect(() => {
    OnboardingManager.loadScript();
  }, []);

  React.useEffect(() => {
    applyTheme(MFEProps.theme);

    const handleSetupAuthUtility = async () => {
      try {
        await setupAuthUtility({
          appName: MFEProps.theme,
          vertical: MFEProps.vertical,
          jwtClient: MFEProps.jwtClient,
          deviceInfo: MFEProps.deviceInfo,
          userFromCustomLink: MFEProps.userFromCustomLink,
        });

        setIsAuthUtilityReady(true);
      } catch (error) {
        throw error;
      }
    };

    handleSetupAuthUtility();

    return () => {
      DebuggingTrack?.close();

      clearLoadingState();

      navigate(VISIBLE_PAGE.START_AUTH_FLOW);
    };
  }, []);

  React.useEffect(() => {
    if (isAuthUtilityReady) {
      const initEffect = async () => {
        await handleAlreadySignedInUser();

        await fillStoreWithPropsData();

        setIsCheckingWhereToStart(false);
      };

      initEffect();
    }
  }, [isAuthUtilityReady]);

  return (
    <Provider theme={MFEProps.theme}>
      <ThemeProvider theme={vitalTheme}>
        <Toaster />

        {isGlobalLoading && <GlobalPageLoader />}

        {isAuthUtilityReady && !isCheckingWhereToStart ? (
          <DynamicPageLoader />
        ) : (
          <GlobalPageLoader />
        )}
      </ThemeProvider>
    </Provider>
  );
};

export default App;
