import { create } from "zustand";
import * as Types from "./types";

const DEFAULT_LOADING_MESSAGE = "Carregando...";

const useGlobalLoading = create<Types.GlobalLoadingStore>((set, get) => ({
  isGlobalLoading: false,
  concludeWithError: false,
  isWaitingRedirect: false,
  concludeWithSuccess: false,
  CustomLoadingComponent: null,
  loadingMessage: DEFAULT_LOADING_MESSAGE,

  setConcludeWithSuccess: (concludeWithSuccess: boolean) =>
    set({ concludeWithSuccess }),

  setConcludeWithError: (concludeWithError: boolean) =>
    set({ concludeWithError, concludeWithSuccess: false }),

  setIsAwaitingRedirect: (isWaitingRedirect: boolean) =>
    set({ isWaitingRedirect }),

  clearLoadingState: () =>
    set({
      isGlobalLoading: false,
      concludeWithError: false,
      concludeWithSuccess: false,
      CustomLoadingComponent: undefined,
      loadingMessage: DEFAULT_LOADING_MESSAGE,
    }),

  setGlobalLoadingState: ({
    isGlobalLoading,
    loadingMessage,
    concludeWithError,
    concludeWithSuccess,
    CustomLoadingComponent,
  }: Types.LoadingStateProps) => {
    const previousState = get();

    set({
      ...previousState,
      isGlobalLoading,
      concludeWithError,
      concludeWithSuccess,
      CustomLoadingComponent,
      loadingMessage: loadingMessage || DEFAULT_LOADING_MESSAGE,
    });

    if (!isGlobalLoading) get().clearLoadingState();
  },
}));

export default useGlobalLoading;
