import * as Types from "./types";
import UserGuiding from "./userGuiding";

class OnboardingManagerController implements Types.IOnboardingManager {
  private loaded: boolean;
  private onboardingService: any;

  constructor(onboardingService) {
    this.loaded = false;
    this.onboardingService = onboardingService;
  }

  public loadScript() {
    if (this.loaded) return;

    this.onboardingService.loadScript();
    this.loaded = true;
  }
}

const userGuidingId = process.env.USER_GUIDING_ID;
const UserGuidingManager = new UserGuiding(userGuidingId);
export const OnboardingManager = new OnboardingManagerController(
  UserGuidingManager
);
