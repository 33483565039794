export enum VISIBLE_PAGE {
  SETUP_TOTP = "setupTOTP",
  START_AUTH_FLOW = "startAuthFlow",
  APPROVE_TOTP_TOKEN = "approveTOTPToken",
  LOGIN_CREDENTIALS = "loginCredentials",
  SECURITY_EMAIL_TOKEN = "securityEmailToken",
  SIGNIN_LOCATION_SELECTOR = "signinLocationSelector",
  MFE_ACCOUNT_VALIDATION = "mfeAccountValidation",
  VINCULATE_CROSS_VERTICAL_USER = "vinculateCrossVerticalUser",
  REGISTER_ACCOUNT = "registerAccount",
  FILL_TOKEN = "fillToken",
  NEW_PASSWORD = "newPassword",
  CONFIGURE_PASSWORD_AND_VINCULATE_COGNITO_ACCOUNT = "configurePasswordAndVinculateCognitoAccount",
}

export const AUTHENTICATED_PAGES = [
  VISIBLE_PAGE.SETUP_TOTP,
  VISIBLE_PAGE.APPROVE_TOTP_TOKEN,
  VISIBLE_PAGE.SECURITY_EMAIL_TOKEN,
  VISIBLE_PAGE.VINCULATE_CROSS_VERTICAL_USER,
  VISIBLE_PAGE.CONFIGURE_PASSWORD_AND_VINCULATE_COGNITO_ACCOUNT,
];

export const COGNITO_AUTHENTICATION_PAGES = [
  VISIBLE_PAGE.SETUP_TOTP,
  VISIBLE_PAGE.APPROVE_TOTP_TOKEN,
];

export const MIGRATION_TO_COGNITO_PAGES = [
  VISIBLE_PAGE.VINCULATE_CROSS_VERTICAL_USER,
  VISIBLE_PAGE.MFE_ACCOUNT_VALIDATION,
  VISIBLE_PAGE.CONFIGURE_PASSWORD_AND_VINCULATE_COGNITO_ACCOUNT,
];
