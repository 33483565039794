import React from "react";

import useNavigate from "@Store/navigate";

import { VISIBLE_PAGE } from "@Constants/views";

const LazyStartAuthFlow = React.lazy(() => import("@Pages/StartAuthFlow"));

const LazySigninLocationSelector = React.lazy(
  () => import("@Pages/SigninLocationSelector")
);

const LazyLoginCredentials = React.lazy(
  () => import("@Pages/LoginCredentials")
);

const LazySecurityEmailToken = React.lazy(
  () => import("@Pages/SecurityEmailToken")
);

const LazySetupTOTP = React.lazy(() => import("@Pages/SetupTOTP"));

const LazyApproveTokenTOTP = React.lazy(
  () => import("@Pages/ApproveTokenTOTP")
);

const LazyAccountValidationMFE = React.lazy(
  () => import("@Pages/MFEValidationAccount")
);

const LazyConfigurePasswordAndVinculateCognitoAccount = React.lazy(
  () => import("@Pages/ConfigurePasswordAndVinculateCognitoAccount")
);

const LazyVinculateCrossVerticalUser = React.lazy(
  () => import("@Pages/VinculateCrossVerticalUser")
);

const LazyForgetPasswordFillToken = React.lazy(
  () => import("@Pages/ForgetPassword/fillToken")
);

const LazyNewPassword = React.lazy(
  () => import("@Pages/ForgetPassword/newPassword")
);

const PageLoader = (
  <div className="flex flex-col w-screen h-screen justify-center items-center">
    Carregando...
  </div>
);

const LazyLoaderComponent = (
  Component: React.LazyExoticComponent<IComponent>
) => (
  <React.Suspense fallback={PageLoader}>
    <Component />
  </React.Suspense>
);

const DynamicPageLoader: IComponent = () => {
  const currentPage = useNavigate((state) => state.currentPage);

  const AVAILABLE_COMPONENT_PAGES = {
    [VISIBLE_PAGE.START_AUTH_FLOW]: LazyLoaderComponent(LazyStartAuthFlow),
    [VISIBLE_PAGE.SIGNIN_LOCATION_SELECTOR]: LazyLoaderComponent(
      LazySigninLocationSelector
    ),
    [VISIBLE_PAGE.LOGIN_CREDENTIALS]: LazyLoaderComponent(LazyLoginCredentials),
    [VISIBLE_PAGE.SETUP_TOTP]: LazyLoaderComponent(LazySetupTOTP),
    [VISIBLE_PAGE.APPROVE_TOTP_TOKEN]:
      LazyLoaderComponent(LazyApproveTokenTOTP),
    [VISIBLE_PAGE.SECURITY_EMAIL_TOKEN]: LazyLoaderComponent(
      LazySecurityEmailToken
    ),
    [VISIBLE_PAGE.MFE_ACCOUNT_VALIDATION]: LazyLoaderComponent(
      LazyAccountValidationMFE
    ),
    [VISIBLE_PAGE.CONFIGURE_PASSWORD_AND_VINCULATE_COGNITO_ACCOUNT]:
      LazyLoaderComponent(LazyConfigurePasswordAndVinculateCognitoAccount),
    [VISIBLE_PAGE.VINCULATE_CROSS_VERTICAL_USER]: LazyLoaderComponent(
      LazyVinculateCrossVerticalUser
    ),
    [VISIBLE_PAGE.FILL_TOKEN]: LazyLoaderComponent(LazyForgetPasswordFillToken),
    [VISIBLE_PAGE.NEW_PASSWORD]: LazyLoaderComponent(LazyNewPassword),
  };

  return AVAILABLE_COMPONENT_PAGES[currentPage];
};

export default DynamicPageLoader;
