import * as Types from "./types";

const USER_GUIDING_SRC = "https://static.userguiding.com/media/user-guiding-";

class UserGuiding implements Types.IOnboardingManager {
  private id: string;

  constructor(id: string) {
    this.id = id;
  }

  loadScript() {
    if (process.env.NODE_ENV !== "production") return;

    // Código gerado pela documentação de implementação do User Guiding
    (function (g, u, i, d, e, s) {
      g[e] = g[e] || [];
      var f = u.getElementsByTagName(i)[0];
      var k: any = u.createElement(i);
      k.async = true;
      k.src = USER_GUIDING_SRC + s + "-embedded.js";
      f.parentNode.insertBefore(k, f);
      if (g[d]) return;
      var ug: any = (g[d] = { q: [] });
      ug.c = function (n) {
        return function () {
          ug.q.push([n, arguments]);
        };
      };
      var m = [
        "previewGuide",
        "finishPreview",
        "track",
        "identify",
        "hideChecklist",
        "launchChecklist",
      ];
      for (var j = 0; j < m.length; j += 1) {
        ug[m[j]] = ug.c(m[j]);
      }
    })(window, document, "script", "userGuiding", "userGuidingLayer", this.id);
  }
}

export default UserGuiding;
