import { SigninAppStore } from "@hyperlocal/auth-utility";

import avecLogoSVG from "@Assets/images/avecLogo.svg";
import crossxLogoSVG from "@Assets/images/crossxLogo.svg";

const APP_LOGOS = {
  avec: avecLogoSVG,
  crossX: crossxLogoSVG,
};

const AppLogo: IComponent = () => {
  const appName = SigninAppStore((state) => state.appName);
  const appLogo = APP_LOGOS[appName];

  return (
    <div className="flex justify-center">
      <img className="h-[40px]" src={appLogo} alt={`${appName}-logo`} />
    </div>
  );
};

export default AppLogo;
