import { avecTheme, crossXTheme } from "@hyperlocal/vital-theme";

export const VITAL_THEMES = {
  avec: avecTheme,
  crossX: crossXTheme,
};

export const GOOGLE_AUTH_LINKS = {
  android:
    "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
  ios: "https://apps.apple.com/us/app/google-authenticator/id388497605",
};
