import React from "react";
import { Button } from "@hyperlocal/vital2";

import useGlobalLoading from "@Store/globalLoading";

import AppLogo from "@Components/BasePage/AppLogo";
import GoBackToSigninButton from "@Components/GoBackToSignInButton";

const TIME_TO_WAIT_LOGIN_REDIRECT = 5000;

const DEFAULT_ERROR_MESSAGE =
  "Algo deu errado! Por favor, retorne ao login e tente novamente.";

const GlobalPageLoader: IComponent = () => {
  const {
    loadingMessage,
    concludeWithError,
    isAwaitingRedirect,
    setIsAwaitingRedirect,
    CustomLoadingComponent,
  } = useGlobalLoading((state) => ({
    loadingMessage: state.loadingMessage,
    concludeWithError: state.concludeWithError,
    isAwaitingRedirect: state.isWaitingRedirect,
    setIsAwaitingRedirect: state.setIsAwaitingRedirect,
    CustomLoadingComponent: state.CustomLoadingComponent,
  }));

  const _loadingMessage = concludeWithError
    ? DEFAULT_ERROR_MESSAGE
    : loadingMessage;

  React.useEffect(() => {
    const handleExitLoginFlowEvent = (event) => {
      setTimeout(() => {
        setIsAwaitingRedirect(event.detail.success);
      }, TIME_TO_WAIT_LOGIN_REDIRECT);
    };

    document.addEventListener(
      "@hyperlocal/auth/exitLoginFlow",
      handleExitLoginFlowEvent
    );

    return () => {
      document.removeEventListener(
        "@hyperlocal/auth/exitLoginFlow",
        handleExitLoginFlowEvent
      );
    };
  }, []);

  return (
    <div
      data-testid="global-page-loader"
      className="fixed inset-0 z-50 flex items-center justify-center bg-white flex-col"
    >
      <div className="max-w-2xl">
        {CustomLoadingComponent ? (
          <CustomLoadingComponent />
        ) : (
          <div className="flex flex-col items-center">
            <div className="mb-7">
              <AppLogo />
            </div>

            {!isAwaitingRedirect && (
              <p className="text-center mx-8">{_loadingMessage}</p>
            )}

            <GoBackToSigninButton />
          </div>
        )}

        {isAwaitingRedirect && (
          <div className="flex items-center justify-center">
            <Button.Root
              variant="link"
              className="items-center"
              onClick={() => window.location.reload()}
            >
              Acessar a plataforma
            </Button.Root>
          </div>
        )}
      </div>
    </div>
  );
};

export default GlobalPageLoader;
