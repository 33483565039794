import { create } from "zustand";

import { VISIBLE_PAGE } from "@Constants/views";

import { NavigateStore } from "./types";

const useNavigate = create<NavigateStore>((set) => ({
  params: {},
  currentPage: VISIBLE_PAGE.START_AUTH_FLOW,
  navigate: (page, params) => {
    set({ currentPage: page, params });
  },
}));

export default useNavigate;
