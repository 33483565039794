import React from "react";
import { Button } from "@hyperlocal/vital2";
import { Auth, SigninAppStore } from "@hyperlocal/auth-utility";

import { VISIBLE_PAGE } from "@Constants/views";

import useNavigate from "@Store/navigate";
import useGlobalLoading from "@Store/globalLoading";

const GoBackToSigninButton: IComponent = () => {
  const navigate = useNavigate((state) => state.navigate);

  const { concludeWithError, clearLoadingState, isAwaitingRedirect } =
    useGlobalLoading((state) => ({
      concludeWithError: state.concludeWithError,
      clearLoadingState: state.clearLoadingState,
      isAwaitingRedirect: state.isWaitingRedirect,
    }));

  const userFromCustomLink = SigninAppStore(
    (state) => state.userFromCustomLink
  );

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleGoBackToSignIn = async () => {
    setIsLoading(true);

    const goToLoginCredentials =
      userFromCustomLink?.authLocation?.salonId ||
      userFromCustomLink?.authLocation?.id;

    const pageToNavigate = goToLoginCredentials
      ? VISIBLE_PAGE.LOGIN_CREDENTIALS
      : VISIBLE_PAGE.START_AUTH_FLOW;

    const clearAuthUtility = false;
    await Auth.handleSignout(clearAuthUtility);

    setIsLoading(false);

    navigate(pageToNavigate, {
      isCommingFromCustomLink: Boolean(userFromCustomLink),
    });

    return clearLoadingState();
  };

  return (
    !isAwaitingRedirect && (
      <Button.Root
        variant="link"
        onClick={handleGoBackToSignIn}
        data-testid="back-to-login-button"
        isLoading={!concludeWithError || isLoading}
      >
        Voltar para o login
      </Button.Root>
    )
  );
};

export default GoBackToSigninButton;
