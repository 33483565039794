import React from "react";

import { Auth } from "@hyperlocal/auth-utility";

import {
  VISIBLE_PAGE,
  COGNITO_AUTHENTICATION_PAGES,
  MIGRATION_TO_COGNITO_PAGES,
} from "@Constants/views";

import useNavigate from "@Store/navigate";

export let removeNavigationHistoryControlEvent = () => null;

let handlePopState = null;

let lastPushTime = 0;
let pushCount = 0;

const MAX_PUSHES = 100;
const TIME_WINDOW = 10000;

const useNavigationHistoryControl = () => {
  const { navigate, currentPage } = useNavigate();

  const goToStartAuthFlow = () => navigate(VISIBLE_PAGE.START_AUTH_FLOW);

  const currentPageRef = React.useRef<VISIBLE_PAGE>(currentPage);

  const handleHistoryBackEvent = (customNavigate?: () => void) => async (e) => {
    const _currentPage = currentPageRef.current;

    const PAGES_WITHOUT_POPSTATE_CONTROL = [
      VISIBLE_PAGE.LOGIN_CREDENTIALS,
      VISIBLE_PAGE.SIGNIN_LOCATION_SELECTOR,
      VISIBLE_PAGE.START_AUTH_FLOW,
      VISIBLE_PAGE.MFE_ACCOUNT_VALIDATION,
    ];

    const isLoginPages = PAGES_WITHOUT_POPSTATE_CONTROL.includes(_currentPage);

    if (isLoginPages) return;

    e.preventDefault();

    history.go(1);

    const backNavigate = customNavigate || goToStartAuthFlow;

    if (COGNITO_AUTHENTICATION_PAGES.includes(_currentPage)) {
      await Auth.handleSignout();

      return window.location.reload();
    }

    if (MIGRATION_TO_COGNITO_PAGES.includes(_currentPage)) {
      await Auth.handleMigrationUserSignout();
    }

    backNavigate();
  };

  const removeNavigationHistoryControl = () => {
    window.removeEventListener("popstate", handlePopState);
  };

  const setNavigationHistoryControl = (customNavigate) => {
    removeNavigationHistoryControl();

    handlePopState = handleHistoryBackEvent(customNavigate);

    const now = Date.now();
    if (now - lastPushTime > TIME_WINDOW) {
      lastPushTime = now;
      pushCount = 0;
    }

    if (pushCount < MAX_PUSHES) {
      window.history.pushState({ name: "mfe" }, "", window.location.pathname);
      pushCount++;
    }

    window.addEventListener("popstate", handlePopState);
  };

  React.useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  React.useEffect(() => {
    removeNavigationHistoryControlEvent = () =>
      removeNavigationHistoryControl();

    return () => removeNavigationHistoryControl();
  }, []);

  return {
    setEventHanlder: setNavigationHistoryControl,
    removeEventHandler: removeNavigationHistoryControl,
  };
};

export default useNavigationHistoryControl;
